import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  FunctionComponent,
} from 'react';
import { debounce, matches, omit } from 'lodash';

import { mapUserType, UserCreationMode, UserTypes } from 'constants/user';
import { InviteUserValidator, NewUserValidator } from 'utils/UserValidator';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import SLocationService from 'services/location/location.service';
import {
  ILocationDetails,
  ILocationMiniInfo,
} from 'interfaces/location.interface';
import { LocationType } from 'constants/location';
import { TagFilter } from 'components/tagFilter/TagFilter.component';
import { TagComponent } from 'components/Tag/tag.component';
import { ITag } from 'interfaces/tag.interface';
import {
  DomainNames,
  ICreateUserDto,
  IInviteUserDto,
  IUserDetailsError,
} from 'interfaces/user.interface';
import SUserService from 'services/user/user.service';
import STeamsService from 'services/teams/teams.service';
import { h2DigitalTeamsForNotification } from 'config/authConfig';
import {
  addNewUserToProjectMsgTeams,
  inviteUserMsgTeams,
} from 'utils/teamsMessage';
import { findTag } from 'utils/tags';
import { TagKeys, TagValues } from 'constants/tag';
import { LocationMultiSelect } from 'components/LocationMultiSelect/LocationMultiSelect.component';

import './NewUserDialog.scss';
import { getServerExceptionType } from 'utils/common';
import { AxiosError } from 'axios';
import { CustomErrorCode } from 'constants/common';

const initialUserDetails = {
  firstName: '',
  lastName: '',
  username: '',
  secondaryEmail: '',
  isAdmin: false,
  // the API doesn't handle isLocked or isSuperUser on creation
  // isLocked: false,
  // isSuperUser: false,
  // TODO: Currently only Provider User is allowed to be created; but any user type can be given when inviting a user. Allow all types in creation as well?
  userType: mapUserType[1].value,
  teamId: '',
  locationIds: [],
  tagIds: [],
};

interface InputType {
  name: string;
  label: string;
}

interface NewUserDialogProps {
  open: boolean;
  refreshUsersList: () => void;
  handleClose: () => void;
}

export const NewUserDialog: FunctionComponent<NewUserDialogProps> = ({
  open,
  refreshUsersList,
  handleClose,
}: NewUserDialogProps) => {
  const checkBoxes: InputType[] = [
    { name: 'isAdmin', label: 'Admin' },
    // { name: 'isSuperUser', label: 'Super User' },
    // { name: 'isLocked', label: 'Locked' },
  ];

  const userImageInput = useRef<HTMLInputElement>(null);
  const [userAvatar, setUserAvatar] = useState<Blob | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [locationList, setLocationList] = useState<ILocationMiniInfo[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [selectedProviderLocationId, setSelectedProviderLocationId] = useState<
    number | null
  >(null);
  const [selectedProjectLocations, setSelectedProjectLocations] = useState<
    ILocationMiniInfo[]
  >([]);
  const [newUserDetails, setNewUserDetails] =
    useState<ICreateUserDto>(initialUserDetails);
  const [userDetailsError, setUserDetailsError] = useState<IUserDetailsError>({
    firstName: '',
    lastName: '',
    username: '',
    secondaryEmail: '',
    userAvatar: '',
  });

  const [providerLocation, setProviderLocation] = useState<ILocationDetails>();
  const [userCreationMode, setUserCreationMode] = useState<UserCreationMode>(
    UserCreationMode.CREATE
  );
  const { showSnackbar } = useSnackbar();

  const handleSwitchUserCreationMode = (mode: UserCreationMode) => {
    if (mode === UserCreationMode.CREATE) {
      setNewUserDetails((prev) => ({ ...prev, userType: UserTypes.Provider }));
    }
    setUserCreationMode(mode);
  };

  const handleCheckBoxValueChange = <Key extends keyof ICreateUserDto>(
    property: Key
  ) => {
    setNewUserDetails((prevUser) => ({
      ...prevUser,
      [property]: !prevUser[property],
    }));
  };

  const deleteTags = (id: number) => {
    setSelectedTags(selectedTags.filter((tag) => tag.id !== id));
  };

  const checkUserNameExists = (signal: AbortSignal) => {
    const userid =
      newUserDetails.username?.toLowerCase() + DomainNames.h2Digital;
    const delayedCheck = debounce(async () => {
      try {
        const exists = await SUserService.checkH2DigitalUserExists(
          userid,
          signal
        );
        const userNameExists = exists.data;
        if (userNameExists && newUserDetails.username) {
          setUserDetailsError((prev) => ({
            ...prev,
            username: `${newUserDetails.username} already exists`,
          }));
        }
      } catch (error) {
        if (error instanceof Error && error.name !== 'CanceledError') {
          console.error(error.message);
        }
      }
    }, 500);
    delayedCheck();
  };

  useEffect(() => {
    SLocationService.findNames(LocationType.Provider).then((response) => {
      if (response && response.length > 0) {
        setLocationList(response);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedProviderLocationId) {
      //getting teams details from location
      SLocationService.getLocationDetails(selectedProviderLocationId).then(
        (res) => {
          setProviderLocation(res);
        }
      );
    }
  }, [selectedProviderLocationId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (newUserDetails.username?.trim().length) checkUserNameExists(signal);
    else setUserDetailsError({});
    return () => {
      controller.abort();
    };
  }, [newUserDetails.username]);

  const onFieldsChange = async (value: string | number, key: string) => {
    if (newUserDetails) {
      setUserDetailsError({
        ...userDetailsError,
        [key]: '',
      });
      setNewUserDetails({
        ...newUserDetails,
        [key]: value,
      });
    }
  };

  const handleLocationChange = (e: SelectChangeEvent<number | null>) => {
    setSelectedProviderLocationId(e.target.value as number);
  };

  const clearUserDetailsErrors = () => {
    setUserDetailsError({
      firstName: '',
      lastName: '',
      username: '',
      secondaryEmail: '',
      userAvatar: '',
    });
  };

  const handleClosePopup = () => {
    setNewUserDetails(initialUserDetails);
    setUserAvatar(undefined);
    setSelectedProviderLocationId(null);
    setSelectedProjectLocations([]);
    setSelectedTags([]);
    setUserCreationMode(UserCreationMode.CREATE);
    handleClose();
    clearUserDetailsErrors();
  };

  const handleSelectNewProjectUserLocations = (
    locations: ILocationMiniInfo[]
  ) => {
    setSelectedProjectLocations(locations);
  };

  const createNewUser = async (
    createUserDto: ICreateUserDto,
    userAvatarBlob: Blob
  ) => {
    try {
      const res = await SUserService.createUser(createUserDto);
      if (res) {
        const { aadUser, userDetails } = res;
        if (h2DigitalTeamsForNotification?.link) {
          const message = addNewUserToProjectMsgTeams({
            locationName: providerLocation?.name ?? '',
            locationId: String(providerLocation?.id) ?? '',
            user: {
              username: aadUser.emailAddress,
              // TODO: it's not safe to return password to the client in a form of json even with HTTPS in my opinion.
              // Discuss with others. This operation can be done in the backend, but currently the specified channel for notifications is not in the backend env.
              password: aadUser.password,
            },
          });
          STeamsService.sendNotificationInTeams(
            h2DigitalTeamsForNotification.link,
            message
          );
        }
        await SUserService.uploadAvatar(userAvatarBlob, userDetails?.id);
        refreshUsersList();
        showSnackbar('User creation success', 'success');
      } else {
        throw new Error('Empty response');
      }
    } catch (error) {
      console.error(error);
      showSnackbar('User creation failed', 'error');
    }
    handleClosePopup();
    setIsLoading(false);
  };

  const inviteNewUser = async (inviteUserDto: IInviteUserDto) => {
    try {
      const res = await SUserService.inviteUser(inviteUserDto);
      if (res) {
        if (h2DigitalTeamsForNotification?.link) {
          const message = inviteUserMsgTeams({
            locationNames: selectedProjectLocations.map(
              (location) => location.name
            ),
            locationIds: selectedProjectLocations.map(
              (location) => location.id
            ),
            user: {
              username: inviteUserDto.secondaryEmail,
              userType: inviteUserDto.userType,
            },
          });
          STeamsService.sendNotificationInTeams(
            h2DigitalTeamsForNotification.link,
            message
          );
        }
        refreshUsersList();
        showSnackbar(
          'User invitation success. He still needs to accept invitation in his email to gain access to the app.',
          'success',
          15000
        );
      } else {
        throw new Error('Empty response');
      }
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        const errorType = getServerExceptionType(error);
        switch (errorType) {
          case CustomErrorCode.USER_ALREADY_INVITED: {
            showSnackbar('User is already invited.', 'error');
            return;
          }
          case CustomErrorCode.INTERNAL_USER_INVITATION_FORBIDDEN: {
            showSnackbar(
              'User with @h2-digital.com domain cannot be invited to H2 Digital because he has access already.',
              'error',
              5000
            );
            return;
          }
          case CustomErrorCode.AAD_USER_NOT_FOUND: {
            showSnackbar(
              'User has been invited before, but now his setup is invalid. Delete the user completely and try again, or contact the development team.',
              'error',
              7000
            );
            return;
          }
        }
      }
      showSnackbar('User invitation failed', 'error');
    }
    setIsLoading(false);
    handleClosePopup();
  };

  const handleSubmit = () => {
    if (!isLoading) {
      clearUserDetailsErrors();
      let errors: IUserDetailsError = {};
      // TODO: Refactor this logic by using ICreateUserDto instead of INewUserDetails
      if (userCreationMode === UserCreationMode.CREATE) {
        errors = NewUserValidator({ ...newUserDetails }, userAvatar);
      } else if (userCreationMode === UserCreationMode.INVITE) {
        errors = InviteUserValidator({ ...newUserDetails });
      }
      setUserDetailsError({ ...errors });
      // In creation mode only Provider user type is enabled now, in invitation - any user type
      const validCreationInvitationParameters =
        (userCreationMode === UserCreationMode.CREATE &&
          userAvatar &&
          newUserDetails.userType === UserTypes.Provider) ||
        userCreationMode === UserCreationMode.INVITE;
      // Project user is valid without any locations assigned
      const validLocationsForUserType =
        (newUserDetails.userType === UserTypes.Provider &&
          selectedProviderLocationId) ||
        [UserTypes.H2DigitalUser, UserTypes.ProjectUser].includes(
          newUserDetails.userType
        );
      if (
        matches(errors)({}) &&
        validCreationInvitationParameters &&
        validLocationsForUserType
      ) {
        setIsLoading(true);
        const createUserDto = {
          ...omit(
            newUserDetails,
            userCreationMode === UserCreationMode.INVITE
              ? ['firstName', 'lastName', 'username']
              : []
          ),
        };

        if (newUserDetails.userType === UserTypes.Provider) {
          let providerOGETag, sourceNEPTag;
          if (providerLocation?.tags) {
            providerOGETag = findTag(
              selectedTags,
              TagKeys.Provider,
              TagValues.OGE
            );
            sourceNEPTag = findTag(selectedTags, TagKeys.Source, TagValues.NEP);
          }
          createUserDto.teamId =
            providerOGETag && sourceNEPTag
              ? process.env.REACT_APP_OGE_CRM_MSTEAMS_ID
              : providerLocation?.MSTeamsID;
        }

        if (
          [UserTypes.Provider, UserTypes.ProjectUser].includes(
            newUserDetails.userType
          )
        ) {
          createUserDto.tagIds = selectedTags.map((tag) => tag.id);
        }

        if (
          newUserDetails.userType === UserTypes.Provider &&
          selectedProviderLocationId
        ) {
          createUserDto.locationIds = [selectedProviderLocationId];
        } else if (newUserDetails.userType === UserTypes.ProjectUser) {
          createUserDto.locationIds = selectedProjectLocations.map(
            (location) => location.id
          );
        }

        if (userCreationMode === UserCreationMode.CREATE && userAvatar) {
          createNewUser(createUserDto as ICreateUserDto, userAvatar);
        } else if (userCreationMode === UserCreationMode.INVITE) {
          inviteNewUser(createUserDto as IInviteUserDto);
        }
      }
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      setUserAvatar(undefined);
      return;
    }
    const file = event.target.files[0];
    setUserDetailsError({
      ...userDetailsError,
      userAvatar: '',
    });
    setUserAvatar(file);
  };

  const handleUserImageClick = () => {
    userImageInput?.current?.click();
  };

  const hasTeamsLinked =
    providerLocation?.MSTeamsID && providerLocation?.MSTeamsChannel;

  const showTeamsErrorMessage =
    newUserDetails.userType === UserTypes.Provider &&
    !hasTeamsLinked &&
    selectedProviderLocationId &&
    providerLocation;

  return (
    <Dialog open={open} className="new-user-dialog-team">
      <DialogTitle>
        {userCreationMode === UserCreationMode.CREATE
          ? 'Create New Provider User'
          : 'Invite External User'}
      </DialogTitle>{' '}
      <DialogContent>
        {showTeamsErrorMessage && (
          <div className="no-team-msg">
            Team details empty!!! Please use TEAMS tab to add Teams to this
            provider location first
          </div>
        )}
        <DialogContentText>
          {userCreationMode === UserCreationMode.CREATE
            ? 'Fill in the form to create a new user'
            : 'Fill in the form to invite an external user by his own email'}
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={() =>
                    handleSwitchUserCreationMode(
                      userCreationMode === UserCreationMode.CREATE
                        ? UserCreationMode.INVITE
                        : UserCreationMode.CREATE
                    )
                  }
                />
              }
              label={'Invite user'}
            />
          </FormGroup>
        </DialogContentText>
        <div className="inputs-container">
          {userCreationMode === UserCreationMode.CREATE ? (
            <>
              <div className="image-container">
                <div className="new-project-image-title">User Image *</div>
                {!userAvatar ? (
                  <div className="new-project-image-wrap">
                    <ImageIcon
                      className="new-project-image-icon"
                      onClick={handleUserImageClick}
                    />
                  </div>
                ) : (
                  <div className="new-project-image-wrap">
                    <img
                      className="new-project-image"
                      src={URL.createObjectURL(userAvatar)}
                      onClick={handleUserImageClick}
                    />
                  </div>
                )}
                <div className="error-class">
                  {userDetailsError?.userAvatar
                    ? 'User Image cannot be empty'
                    : ''}
                </div>
              </div>
              <input
                type="file"
                ref={userImageInput}
                onChange={handleImageChange}
                style={{ display: 'none' }}
                accept="image/png, image/jpeg"
              />
            </>
          ) : (
            <></>
          )}
          <div className="textfield-container">
            {userCreationMode === UserCreationMode.CREATE && (
              <>
                <TextField
                  rows={2}
                  required
                  label="First Name"
                  className="text-input"
                  error={userDetailsError.firstName ? true : false}
                  helperText={userDetailsError.firstName || ''}
                  margin="dense"
                  type="text"
                  variant="standard"
                  onChange={(e) => onFieldsChange(e.target.value, 'firstName')}
                />
                <TextField
                  rows={2}
                  required
                  label="Last Name"
                  className="text-input"
                  error={userDetailsError.lastName ? true : false}
                  helperText={userDetailsError.lastName || ''}
                  margin="dense"
                  type="text"
                  variant="standard"
                  onChange={(e) => onFieldsChange(e.target.value, 'lastName')}
                />
                <TextField
                  rows={2}
                  required
                  label="Username"
                  className="text-input"
                  error={userDetailsError.username ? true : false}
                  helperText={userDetailsError.username || ''}
                  margin="dense"
                  type="text"
                  variant="standard"
                  onChange={(e) => onFieldsChange(e.target.value, 'username')}
                />
                <TextField
                  rows={2}
                  required
                  label="Domain"
                  className="text-input"
                  value={DomainNames.h2Digital}
                  margin="dense"
                  type="text"
                  variant="standard"
                  disabled
                />
              </>
            )}
            <TextField
              rows={2}
              required
              label={
                userCreationMode === UserCreationMode.INVITE
                  ? 'Email'
                  : 'Secondary Email'
              }
              className={`${
                userCreationMode === UserCreationMode.INVITE
                  ? 'secondary-email-input-in-invitation-mode'
                  : ''
              } text-input`}
              autoFocus
              error={userDetailsError.secondaryEmail ? true : false}
              helperText={userDetailsError.secondaryEmail || ''}
              margin="dense"
              type="text"
              variant="standard"
              onChange={(e) => onFieldsChange(e.target.value, 'secondaryEmail')}
            />
            <div className="field-select">
              <FormControl>
                <InputLabel id="user-type-select-label">User Type</InputLabel>
                <Select
                  required
                  variant="outlined"
                  labelId="user-type-select-label"
                  label="User Type"
                  fullWidth={true}
                  value={newUserDetails.userType}
                  onChange={(e) => onFieldsChange(e.target.value, 'userType')}
                  disabled={userCreationMode === UserCreationMode.CREATE}
                >
                  {mapUserType.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <FormGroup className="checkbox-container">
          {checkBoxes.map((checkBox, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    value={
                      newUserDetails[checkBox.name as keyof ICreateUserDto]
                    }
                    onChange={() => {
                      handleCheckBoxValueChange(
                        checkBox.name as keyof ICreateUserDto
                      );
                    }}
                  />
                }
                label={checkBox.label}
              />
            );
          })}
        </FormGroup>

        {newUserDetails.userType === UserTypes.Provider ? (
          <div className="provider-location-select">
            <FormControl>
              <InputLabel id="provider-location-select-label">
                Provider Location
              </InputLabel>
              <Select
                required
                variant="outlined"
                labelId="provider-location-select-label"
                label="Provider Location"
                id="provider-location-select"
                fullWidth={true}
                value={selectedProviderLocationId || ''}
                onChange={handleLocationChange}
              >
                {locationList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        ) : (
          <></>
        )}
        {newUserDetails.userType === UserTypes.ProjectUser ? (
          <LocationMultiSelect
            locationType={LocationType.Project}
            selectedLocations={selectedProjectLocations}
            onChange={handleSelectNewProjectUserLocations}
          />
        ) : (
          <></>
        )}
        {[UserTypes.ProjectUser, UserTypes.Provider].includes(
          newUserDetails.userType
        ) ? (
          <div className="tags-container">
            <div className="tags-title">
              <Typography variant="body1">Add Tags</Typography>
              {/* TODO: check if backend method is already copying provider tags from the linked location to the user */}
              <Tooltip title="Please add corresponding Provider tag">
                <Info />
              </Tooltip>
            </div>
            <TagFilter
              tags={selectedTags}
              onChange={(tags: ITag[]) => {
                setSelectedTags(tags);
              }}
            />
            <div className="tags-container-pop-up">
              {selectedTags.map((tag: ITag) => {
                return (
                  <TagComponent
                    key={tag.id}
                    deleteEnabled={true}
                    deleteAction={deleteTags}
                    tag={tag}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
        <DialogActions className="action-btns">
          <Button onClick={handleClosePopup}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={
              isLoading ||
              (newUserDetails.userType === UserTypes.Provider &&
                !hasTeamsLinked) ||
              (userCreationMode === UserCreationMode.CREATE &&
                newUserDetails.userType !== UserTypes.Provider)
            } //not allowing user creation if team details are empty/no provider location selected for provider user type
          >
            {isLoading ? (
              <CircularProgress size={'20px'}></CircularProgress>
            ) : userCreationMode === UserCreationMode.CREATE ? (
              'Create'
            ) : (
              'Invite'
            )}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
export default NewUserDialog;
